
import { defineComponent } from "vue";
import useChecked from "@/hooks/checking/spotChecking/useChecked";
import { elementSize } from "@/utils";
import UploadDetail from "./uploadDetail.vue";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
// import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import AllEntitySelector from "@/views/Payment/components/AllEntitySelector.vue";

export default defineComponent({
  name: "paymentApproveMgmt",
  components: {
    UploadDetail,
    BuSelector,
    // EntitySelector,
    AllEntitySelector
  },
  props: {},
  setup() {
    //#region [引用useApprovalMain.ts相关]
    const {
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      uploadVisible,
      payoutRoundInfo,
      openUpload,
      closeUpload,
      handleGoToPackageCodeOverview
    } = useChecked();

    const tableSize = elementSize(".ant-table-wrapper");

    return {
      searchParams,
      dealerColData,
      afcAndLcColData,
      dealerTblData,
      afcAndLcTblData,
      doSearch,
      doReset,
      tableSize,
      uploadVisible,
      payoutRoundInfo,
      openUpload,
      closeUpload,
      handleGoToPackageCodeOverview
    };
  },
});
